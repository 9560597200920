<template>
  <div class="PhoneVerification">
    <div class="loader-container"
         v-if="isMyAccountLoading" >
      <Loader />
    </div>
    <div v-else>
      <md-card v-if="accountConnectionError" class="card-phone-activation">
        <md-card-header>
          <div class="md-title u-red">{{ $t('phoneVerification.connectionError') }}</div>
        </md-card-header>
        <md-card-content>
          <span class="o-default-body u-red">
            {{ $t('phoneVerification.pageCouldNotLoad') }} {{ $t('contactEmailInreg') }}
          </span>
        </md-card-content>
      </md-card> <!-- /.card-phone-activation -->

      <div v-else>
        <md-card class="card-phone-activation"
                 v-if="phoneVerified">
          <md-card-header>
            <div class="md-title">{{ $t('phoneVerification.phoneVerified') }}</div>
            <span class="o-default-body">{{ $t('phoneVerification.phoneVerificationDetails') }}</span>
          </md-card-header>
          <md-card-content>
            <div class="u-green">
              <i class="material-icons md-20">check_circle</i>
              <span class="o-default-h4 u-green phone-number-confirmed">{{ phoneNumber }}</span>
            </div>
          </md-card-content>
        </md-card> <!-- /.card-phone-activation -->

        <md-card v-else class="card-phone-activation">
          <md-card-header>
            <div class="md-title">{{ $t('phoneVerification.confirmPhone') }}</div>
            <span class="o-default-body">{{ $t('phoneVerification.phoneVerificationDetails') }}</span>
          </md-card-header>

          <md-card-content>
            <form v-if="!authCodeSent"
                  @submit.prevent="validatePhoneNumber"
                  novalidate>
              <vue-tel-input class="phone-input"
                             :class="{ 'u-red u-border-red': showErrors() }"
                             v-model="formPhone.phoneNumber"
                             @input="onPhoneInput"
                             v-bind="vueTelInputProps">
              </vue-tel-input>
              <md-button type="submit" class="md-dense md-raised md-primary phone-input-btn inline-input-btn">
                {{ $t('phoneVerification.sendActivationCode') }}
              </md-button>
              <div class="u-red phone-input-error"
                   v-if="showErrors()">
                <div>{{ $t('common.phone.error.invalid') }}</div>
                <div>{{ $t('common.error.furtherIssuesContact') }} {{ $t('contactEmailInreg') }}</div>
              </div>
            </form>
            <div v-if="authCodeSent">
              <div class="o-default-h4 u-green">{{ $t('phoneVerification.smsSent') }}</div>
              <div class="o-default-h4 u-green u-strong">{{ phoneNumber }}</div>

              <div class="phone-input-activation-container">
                <div v-if="!phoneVerifiedTemp" class="phone-input-activation-layout-item">

                  <div class="md-layout">
                    <form @submit.prevent="validateActivationCode"
                          novalidate
                          class="md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100">
                      <md-field class="md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100 validation-code-input"
                                :class="getValidationClassPhone('activationCode')">
                        <label for="activation-code">{{ $t('phoneVerification.enterActivationCode') }}</label>
                        <md-input name="activation-code"
                                  id="activation-code"
                                  class="md-layout-item"
                                  autocomplete="activation-code"
                                  v-model="formPhone.activationCode"
                                  :disabled="sendingFormPhone" />
                        <span class="md-error" v-if="!$v.formPhone.activationCode.required">
                          {{ $t('phoneVerification.pleaseEnterActivationCode') }}
                        </span>
                      </md-field>
                      <div v-if="phoneErrors && $v.formPhone.activationCode.required"
                           v-html="phoneErrors"
                           class="md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100 u-default-body u-red phone-input-error">
                      </div>
                      <md-button class="md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100 md-dense md-raised md-primary phone-input-btn confirm-number u-light-green-background"
                                 type="submit">
                        {{ $t('phoneVerification.confirmNumber') }}
                      </md-button>
                      <div class="md-layout md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
                        <md-button @click="retryCode()"
                                   class="md-layout-item md-xlarge-size-45 md-large-size-45 md-medium-size-45 md-small-size-100 md-xsmall-size-100 md-dense md-raised md-primary phone-input-btn">
                          <Loader v-if="authCodeResentLoading" />
                          <span v-else>{{ $t('phoneVerification.retryCode') }}</span>
                        </md-button>
                        <div class="md-layout-item md-xlarge-size-10 md-large-size-10 md-medium-size-10 md-small-size-0 md-xsmall-size-0"></div>
                        <md-button class="md-layout-item md-xlarge-size-45 md-large-size-45 md-medium-size-45 md-small-size-100 md-xsmall-size-100 md-dense md-raised md-accent phone-input-btn"
                                   @click="changePhone()">
                          {{ $t('phoneVerification.changePhone') }}
                        </md-button>
                      </div>
                      <div class="successful-code-retry"
                           v-if="authCodeResent && !phoneErrors">
                        <div class="o-default-h4 u-green">{{ $t('phoneVerification.retryCodeConfirmation') }}</div>
                        <div class="o-default-h4 u-green u-strong">{{ phoneNumber }}</div>
                      </div>
                    </form>
                  </div>
                </div> <!-- /.phone-input-activation-layout-item -->
                <div v-if="phoneVerifiedTemp" class="o-default-h4 u-green account-phone-final-confirmation">
                  {{ $t('phoneVerification.numberSuccessfullyConfirmed') }}
                  <span @click="Helpers.goToRoute('auctions', $router)" class="u-cursor-pointer u-text-underline">
                    {{ $t('phoneVerification.goToAuctions') }}
                  </span>
                </div> <!-- /.account-phone-final-confirmation -->
              </div> <!-- /.phone-input-activation-container -->
              <md-progress-bar md-mode="indeterminate" v-if="sendingFormPhone" />
            </div>
          </md-card-content>
        </md-card> <!-- /.card-phone-activation -->
      </div>
    </div>
  </div> <!-- /.PhoneVerification -->
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Loader from '@/components/util/Loader';
import {
  FETCH_PHONE,
  ADD_PHONE,
  RETRY_CODE,
  CHANGE_PHONE,
  VERIFY_PHONE,
} from '@/store/actions.type';
import {
  SET_AUTH_CODE_SENT,
  RESET_PHONE_ERROR
} from '@/store/mutations.type';

export default {
  name: 'PhoneVerification',
  mixins: [validationMixin],
  components: {
    Loader
  },
  data() {
    return {
      errors: {
        validPhone: false,
        showInvalidPhone: false
      },
      formPhone: {
        phoneNumber: '',
        activationCode: ''
      },
      vueTelInputProps: {
        mode: 'international',
        autoDefaultCountry: false,
        preferredCountries: ['ro'],
        inputOptions: {
          placeholder: this.$t('common.phone.phoneNumber')
        }
      },
      triedValidation: false,
      sendingFormPhone: false,
      authCodeResent: false,
      authCodeResentLoading: false
    };
  },
  computed: {
    ...mapGetters([
      'isMyAccountLoading',
      'authCodeSent',
      'phoneNumber',
      'phoneVerified',
      'phoneVerifiedTemp',
      'accountConnectionError',
      'phoneErrors',
    ]),
  },
  created() {
    this.fetchPhone();
  },
  methods: {
    fetchPhone() {
      this.$store.dispatch(FETCH_PHONE)
        .then(() => {
          if (this.phoneNumber) {
            this.$store.commit(SET_AUTH_CODE_SENT, true);
          }
        });
    },
    retryCode() {
      this.isLoading = true;
      this.$store.dispatch(RETRY_CODE)
        .then(() => {
          this.authCodeResent = true;
        })
        .catch(() => {
          this.authCodeResent = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    changePhone() {
      this.isLoading = true;
      this.$store.dispatch(CHANGE_PHONE)
        .then(() => {
          this.authCodeResent = true; // @todo to check if this should be here or not
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showErrors() {
      if (this.triedValidation) {
        if (!this.errors.validPhone) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getValidationClassPhone(fieldName) {
      const field = this.$v.formPhone[fieldName]

      if (field) {
        return { 'md-invalid': field.$invalid && field.$dirty }
      }
    },
    sendActivationCode() {
      this.sendingFormPhone = true;
      this.$store
        .dispatch(VERIFY_PHONE, this.formPhone.activationCode)
        .then(this.sendingFormPhone = false);
    },
    onPhoneInput(number, phoneObj) {
      this.$store.commit(RESET_PHONE_ERROR);
      this.errors.validPhone = phoneObj.valid;
    },
    validatePhoneNumber() {
      this.triedValidation = true;
      if (this.errors.validPhone) {
        this.errors.showInvalidPhone = false;
        this.authCodeResent = false;
        this.$store.dispatch(ADD_PHONE, this.formPhone.phoneNumber);
      } else {
        this.errors.showInvalidPhone = true;
      }
    },
    validateActivationCode() {
      this.$v.formPhone.$touch();

      if (!this.$v.formPhone.$invalid) {
        this.sendActivationCode();
      }
    },
  },
  validations: {
    formPhone: {
      activationCode: {
        required
      }
    }
  },
};
</script>

<style lang="scss">
.PhoneVerification {
  position: relative;
  z-index: 9;

  .md-title {
    font-size: 2.4rem;
    line-height: 1;
    padding-bottom: .8rem;
  }

  .md-card-content {
    padding: 1rem 2rem 2rem;
  }

  .account-phone-final-confirmation {
    line-height: 4.8rem;
    margin-top: .4rem;
  }

  .card-phone-activation {
    margin-bottom: 1.6rem;

    .phone-number-confirmed {
      display: inline-block;
      margin-left: .8rem;
      transform: translate(0, -.5rem);
    }

    .phone-input {
      box-shadow: 0 .2rem .1rem -.1rem rgba(0, 0, 0, .2), 0 .1rem .1rem 0 rgba(0, 0, 0, .14), 0 .1rem .3rem 0 rgba(0, 0, 0, .12);
      display: inline-flex;
      height: 4rem;
      min-width: 26rem;
      position: relative;
      width: 100%;
      z-index: 9;
      @include tablet {
        border-radius: .4rem 0 0 .4rem;
        width: 50%;
      }
      @include desktop {
        width: 40%;
      }

      &.u-red input {
        color: $palette-error;
      }
    }

    .phone-input-btn {
      height: 4rem;
      margin: 1.6rem 0 0;

      &.inline-input-btn {
        display: inline-block;
        margin: 2.4rem 0 0;
        min-width: 18rem;
        max-width: 28rem;
        width: 100%;
        @include tablet {
          border-radius: 0 .4rem .4rem 0;
          margin: 0;
          width: 30%;
        }
        @include desktop {
          width: 40%;
        }
      }

      &.confirm-number {
        margin: 2.4rem 0;
        @include tablet {
          margin: 2.4rem 0 1.6rem;
        }
      }
    }

    .phone-input-error {
      font-family: inherit;
      font-size: 1.2rem;
      margin-top: .8rem;
    }

    .phone-input-activation-container {
      margin: 0 0 1.6rem;
    }

    .phone-input-activation-layout-item {
      padding-left: 0;
    }

    .successful-code-retry {
      margin-top: 2.4rem;
    }

    .validation-code-input {
      border-radius: .4rem 0 0 .4rem;
      height: 4rem;
      margin-bottom: 0;
      min-width: 26rem;
      padding-left: 0;
    }
  }
}
</style>
