<template>
  <div class="ChooseLanguageCard">
    <md-card class="card-content">
      <md-card-header class="account-language-card-header">
        <span class="material-icons">language</span>
        <span class="md-title account-language-card-header">{{ $t('footer.chooseLanguage') }}</span>
      </md-card-header>
      <md-divider></md-divider>
      <md-card-content class="select-language">
        <LocaleSelector :isMyAccountView="true" class="md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100 with-divider locale-input"/>
      </md-card-content>
    </md-card>
  </div> <!-- /.ChooseLanguageCard -->
</template>


<script>
import LocaleSelector from '@/components/footer/LocaleSelector';

export default {
  name: 'ChooseLanguageCard',
  components: {
    LocaleSelector
  },
};
</script>

<style lang="scss" scoped>
.ChooseLanguageCard {
  margin-top: 2rem;

  .select-language {
    padding-left: 1.8rem;
  }

  .account-language-card-header {
    display: flex;
    gap: .5rem;
  }

  .card-content {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
</style>
