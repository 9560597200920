<template>
  <div class="UserDetailsEmpty">
    <md-card class="card-user-details-empty">
      <md-empty-state v-if="!isUserDetailsAddState"
                      md-icon="add_business"
                      :md-label="emptyState.label"
                      :md-description="emptyState.description">
        <md-button @click="addNewDetails()"
                   class="md-primary md-raised add-new-details-btn">
          {{ $t('common.actions.addRegistrationDetails') }}
        </md-button>
        <div @click="addNewDetails()" class="clickable-overlay"></div>
      </md-empty-state>
      <div v-else>
        <md-card-header>
          <md-card-header-text>
            <div class="md-title">{{ $t('userDetailsEmpty.titleAdd') }}</div>
            <span class="o-default-body">{{ $t('userDetailsEmpty.descriptionAdd') }}</span>
          </md-card-header-text>
        </md-card-header>
        <md-card-content>
          <UserDetailsForm />
        </md-card-content>
      </div>
    </md-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Helpers from '@/common/helpers.js';
import UserDetailsForm from '@/components/user-details/UserDetailsForm.vue';
import {
  RESET_USER_DETAILS,
  SET_USER_DETAILS_ADD_STATE_FLAG
} from '@/store/actions.type';

export default {
  name: 'UserDetailsEmpty',
  components: {
    UserDetailsForm
  },
  data() {
    return {
      emptyState: {
        label: this.$t('userDetailsEmpty.label'),
        description: this.$t('userDetailsEmpty.description')
      }
    };
  },
  computed: {
    ...mapGetters(['isUserDetailsAddState'])
  },
  methods: {
    addNewDetails() {
      this.$store.dispatch(RESET_USER_DETAILS)
        .then(() => {
          this.$store.dispatch(SET_USER_DETAILS_ADD_STATE_FLAG, true)
            .then(() => {
              Helpers.scrollIntoView('UserDetailsEmpty');
            });
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.UserDetailsEmpty {

  .card-user-details-empty {
    margin: 0 0 1.6rem;
  }

  .add-new-details-btn {
    height: 4rem;
    min-width: 20rem;
    padding: 0 .8rem;
  }

  .clickable-overlay {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
