<template>
  <div class="UserDetailsDefault">
    <div class="md-layout">
      <div class="md-layout-item">
        <div class="summary-group" v-if="userDetailsDefault.registrantName">
          <div class="o-default-h5">{{ $t('common.addressLabels.fullName') }}</div>
          <div class="o-default-body">{{ userDetailsDefault.registrantName }}</div>
        </div>
        <div class="summary-group" v-if="userDetailsDefault.personType">
          <div class="o-default-h5">{{ $t('common.addressLabels.type') }}</div>
          <div class="o-default-body">{{ Helpers.showEntityType(userDetailsDefault.personType) }}</div>
        </div>
        <div class="summary-group" v-if="userDetailsDefault.cnp">
          <div class="o-default-h5">{{ $t('common.addressLabels.identificationNumber') }}</div>
          <div class="o-default-body">{{ userDetailsDefault.cnp }}</div>
        </div>
        <div class="summary-group" v-if="userDetailsDefault.registrationNumber">
          <div class="o-default-h5">{{ $t('common.addressLabels.referenceNumber') }}</div>
          <div class="o-default-body">{{ userDetailsDefault.registrationNumber }}</div>
        </div>
      </div>
      <div class="md-layout-item">
        <div class="summary-group" v-if="userDetailsDefault.email">
          <div class="o-default-h5">{{ $t('common.addressLabels.email') }}</div>
          <div class="o-default-body">{{ userDetailsDefault.email }}</div>
        </div>
        <div class="summary-group" v-if="userDetailsDefault.phone">
          <div class="o-default-h5">{{ $t('common.addressLabels.phone') }}</div>
          <div class="o-default-body">{{ userDetailsDefault.phone }}</div>
        </div>
      </div>
      <div class="md-layout-item">
        <div class="summary-group" v-if="userDetailsDefault.address1">
          <div class="o-default-h5">{{ $t('common.addressLabels.address') }}</div>
          <div class="o-default-body">
            <span>{{ userDetailsDefault.address1 }}</span>
            <span v-if="userDetailsDefault.address2">, {{ userDetailsDefault.address2 }}</span>
            <span v-if="userDetailsDefault.city">, {{ userDetailsDefault.city }}</span>
            <span v-if="userDetailsDefault.state">, {{ userDetailsDefault.state }}</span>
            <span v-if="userDetailsDefault.country">, {{ userDetailsDefault.country }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserDetailsDefault',
  props: {
    userDetailsDefault: {
      type: Object,
      required: true
    },
  },
}
</script>

<style lang="scss" scoped>
.UserDetailsDefault {

  .md-layout {

    .md-layout-item {
      flex: 0 0 100%;
      margin-right: .8rem;
      @include tablet { flex: 1 1; }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .o-default-h5 {
    color: $palette-secondary-color-four;
    margin-bottom: .4rem;
  }

  .summary-group {
    margin: 1.6rem 0;
  }
}
</style>
