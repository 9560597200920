<template>
  <div class="UserDetailsSummary">
    <md-card class="card-user-details-summary">
      <md-card-header>
        <md-card-header-text>
          <div class="md-title">{{ $t('userDetailsSummary.title') }}</div>
          <span class="o-default-body">{{ $t('userDetailsSummary.description') }}</span>
        </md-card-header-text>

        <md-menu md-size="big" md-direction="bottom-end">
          <md-button class="md-icon-button" md-menu-trigger>
            <md-icon>more_vert</md-icon>
          </md-button>

          <md-menu-content>
            <md-menu-item @click="editUserDetails(userDetailsDefault)">
              <span>{{ $t('common.actions.edit') }}</span>
              <md-icon>edit</md-icon>
            </md-menu-item>
          </md-menu-content>
        </md-menu>
      </md-card-header>

      <Loader v-if="isLoading" />
      <md-card-content v-else-if="isUserDetailsEditState">
        <UserDetailsForm />
      </md-card-content>
      <md-card-content v-else>
        <UserDetailsDefault :userDetailsDefault="userDetailsDefault" />
      </md-card-content>
    </md-card>
  </div> <!-- /.UserDetailsSummary -->
</template>

<script>
import { mapGetters } from 'vuex';
import Helpers from '@/common/helpers.js';
import Loader from '@/components/util/Loader';
import UserDetailsForm from '@/components/user-details/UserDetailsForm.vue';
import UserDetailsDefault from '@/components/user-details/UserDetailsDefault';
import {
  SET_USER_DETAILS_EDIT_STATE_FLAG,
  UPDATE_USER_DETAILS
} from '@/store/actions.type';
import {
  SET_RESET_USER_DETAILS_ERROR
} from '@/store/mutations.type';

export default {
  name: 'UserDetailsSummary',
  props: {
    userDetailsDefault: {
      type: Object,
      required: true
    }
  },
  components: {
    Loader,
    UserDetailsForm,
    UserDetailsDefault
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(['isUserDetailsEditState'])
  },
  methods: {
    editUserDetails(details) {
      this.isLoading = true;
      this.$store.dispatch(UPDATE_USER_DETAILS, details)
        .then(() => {
          this.$store.dispatch(SET_USER_DETAILS_EDIT_STATE_FLAG, true)
            .then(() => {
              this.$store.commit(SET_RESET_USER_DETAILS_ERROR);
              Helpers.scrollIntoView('UserDetailsSummary');
            })
            .finally(() => {
              this.isLoading = false;
            });
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.UserDetailsSummary {

  .card-user-details-summary {
    margin: 1.6rem 0;
  }
}
</style>
