import { render, staticRenderFns } from "./UserDetailsEmpty.vue?vue&type=template&id=4cc88756&scoped=true"
import script from "./UserDetailsEmpty.vue?vue&type=script&lang=js"
export * from "./UserDetailsEmpty.vue?vue&type=script&lang=js"
import style0 from "./UserDetailsEmpty.vue?vue&type=style&index=0&id=4cc88756&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cc88756",
  null
  
)

export default component.exports