<template>
  <div class="UserDetailsView">
    <Loader v-if="isUserDetailsLoading" />
    <div v-else>
      <PhoneVerification />
      <AccountCurrency />
      <ChooseLanguageCard/>
      <div v-if="userDetailsAddresses && userDetailsAddresses.length">
        <UserDetailsSummary v-if="userDetailsDefault" :userDetailsDefault="userDetailsDefault" />
        <UserDetailsOther v-if="userDetailsNonDefault" :userDetailsNonDefault="userDetailsNonDefault" />
        <UserDetailsAdd />
      </div>
      <UserDetailsEmpty v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store';
import AccountCurrency from '@/components/account/AccountCurrency.vue';
import ChooseLanguageCard from '@/components/util/ChooseLanguageCard';
import Loader from '@/components/util/Loader';
import PhoneVerification from '@/components/PhoneVerification';
import UserDetailsAdd from '@/components/user-details/UserDetailsAdd';
import UserDetailsEmpty from '@/components/user-details/UserDetailsEmpty';
import UserDetailsOther from '@/components/user-details/UserDetailsOther';
import UserDetailsSummary from '@/components/user-details/UserDetailsSummary';
import {
  FETCH_USER_DETAILS_ALL,
  RESET_USER_DETAILS_MODULE,
} from '@/store/actions.type';
import {
  SET_USER_DETAILS_LOADING
} from '@/store/mutations.type';

export default {
  name: 'UserDetailsView',
  components: {
    AccountCurrency,
    ChooseLanguageCard,
    Loader,
    PhoneVerification,
    UserDetailsAdd,
    UserDetailsEmpty,
    UserDetailsOther,
    UserDetailsSummary
  },
  async beforeRouteLeave(to, from, next) {
    await store.dispatch(RESET_USER_DETAILS_MODULE);
    next();
  },
  computed: {
    ...mapGetters([
      'isUserDetailsLoading',
      'userDetailsAddresses',
      'userDetailsDefault',
      'userDetailsNonDefault'
    ])
  },
  created() {
    this.$store.commit(SET_USER_DETAILS_LOADING, true);
    this.$store.dispatch(FETCH_USER_DETAILS_ALL)
      .finally(() => {
        this.$store.commit(SET_USER_DETAILS_LOADING, false);
      });
  }
};
</script>
