<template>
  <div class="AccountCurrency">
    <md-card>
      <md-card-header class="account-currency-card-header">
        <span class="material-icons">currency_exchange</span>
        <span class="md-title account-currency-card-header-title">{{ $t('account.currency.title') }}</span>
      </md-card-header>
      <md-divider></md-divider>
      <md-card-content class="section-form-layout">
        <form @submit.prevent="saveAccountCurrency" novalidate>
          <md-field :class="getValidationClass('currency')">
            <label for="account-currency">{{ $t('common.currency') }}</label>
            <md-select id="account-currency"
                       v-model="accountCurrency"
                       md-dense
                       @md-selected="resetErrors"
                       :disabled="isFormSending"
                       :placeholder="accountCurrency">
              <md-option value="RON">{{ $t('common.currencies.ron') }}</md-option>
              <md-option value="EUR">{{ $t('common.currencies.eur') }}</md-option>
            </md-select>
            <span class="md-error" v-if="!$v.currency.required">{{ $t('account.currency.error.currencyRequired') }}</span>
          </md-field>
          <md-button type="submit"
                     class="md-raised md-primary submit-btn"
                     :disabled="isFormSending">
            {{ $t('common.actions.save') }}
          </md-button>
        </form>
      </md-card-content>
    </md-card>
  </div> <!-- /.AccountCurrency -->
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Helpers from '@/common/helpers.js';
import {
  POST_BILLING_CURRENCY,
  SHOW_CONFIRMATION_DIALOG,
  FETCH_CURRENCY,
} from '@/store/actions.type';
import {
  SET_RESET_CURRENCY_ERROR
} from '@/store/mutations.type';
export default {
  name: 'AccountCurrency',
  mixins: [validationMixin],
  data() {
    return {
      accountCurrency: this.currency,
      isFormSending: false,
    };
  },
  watch: {
    accountCurrency: function (val) {
      this.accountCurrency = val;
    },
  },
  created() {
    this.fetchCurrency();
  },
  mounted() {
    this.resetErrors();
  },
  computed: {
    ...mapGetters([
      'currencyErrors',
      'currency'
    ]),
  },
  methods: {
    fetchCurrency() {
      this.$store.dispatch(FETCH_CURRENCY).then(() => {
        this.accountCurrency = this.currency;
      })
    },
    resetErrors() {
      this.$v.$reset();
      this.$store.commit(SET_RESET_CURRENCY_ERROR);
    },
    getValidationClass(fieldName) {
      const field = this.$v[fieldName];

      if (field) {
        return { 'md-invalid': field.$invalid && field.$dirty };
      }
    },
    validateCurrency() {
      if (!this.accountCurrency) {
        this.$v.currency.$touch();
      }
    },
    handleUpdateError() {
      Helpers.scrollIntoView('u-red');
      this.isFormSending = false;
    },
    showConfirmationAndScrollTo(scrollAnchor) {
      Helpers.scrollIntoView(scrollAnchor);
      this.isFormSending = false;
      setTimeout(() => {
        this.$store.dispatch(SHOW_CONFIRMATION_DIALOG);
      }, 500);
    },
    saveAccountCurrency() {
      if (!this.accountCurrency || this.currencyErrors) {
        return this.validateCurrency();
      }
      let params = {
        currency: this.accountCurrency,
      }
      this.isFormSending = true;
      this.$store.dispatch(POST_BILLING_CURRENCY, params)
        .then(() => {
          this.showConfirmationAndScrollTo('AccountCurrency');
        })
        .catch(() => {
          this.handleUpdateError();
        });
    },
  },
  validations: {
    currency: {
      required,
    },
  },
};
</script>

<style lang="scss" scoped>
.AccountCurrency {

  .account-currency-card-header {
    align-items: center;
    display: flex;
    gap: .5rem;
    margin-bottom: .8rem;
  }

  .account-currency-card-header-title {
    padding-bottom: 0;
  }

  .section-form-layout {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .submit-btn {
    margin: 0;
  }
}
</style>
