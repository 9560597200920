<template>
  <div class="UserDetailsOther">
    <md-card class="card-user-details-other">
      <md-card-header>
        <md-card-header-text>
          <div class="md-title">{{ $t('userDetailsOther.title') }}</div>
          <div class="o-default-body">{{ $t('userDetailsOther.description') }}</div>
        </md-card-header-text>
      </md-card-header>

      <Loader v-if="isLoading" />
      <md-card-content v-else-if="isUserDetailsOtherEditState">
        <UserDetailsForm />
      </md-card-content>

      <md-card-content v-else>
        <md-list>
          <md-list-item v-for="item in userDetailsNonDefault"
                        :key="item.id"
                        @click="showUserDetailsItemDialog(item)"
                        class="with-divider">
            <UserDetailsItem :userDetailsItem="item" />
          </md-list-item>
        </md-list>
      </md-card-content>
    </md-card>

    <md-dialog :md-active.sync="confirmAction.showDialog" class="o-dialog-confirmation">
      <md-dialog-title>{{ $t('common.confirmation.pleaseConfirm') }}</md-dialog-title>
      <div class="o-dialog-content">
        <div class="o-default-body">{{ confirmAction.message }}</div>
      </div>
      <md-dialog-actions>
        <md-button class="md-raised" @click="confirmAction.showDialog = false">{{ $t('common.actions.cancel') }}</md-button>
        <md-button class="md-raised md-primary u-light-green-background" @click="handleFunctionCall(confirmActionFooParam)">{{ $t('common.actions.confirm') }}</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog v-if="userDetailsItem" :md-active.sync="showDialog">
      <md-dialog-title>{{ $t('userDetailsOther.editModalTitle') }}</md-dialog-title>
      <div class="o-dialog-content">
        <div class="o-default-h5">{{ userDetailsItem.registrantName }} - {{ Helpers.showEntityType(userDetailsItem.personType) }}</div>
        <div v-if="userDetailsItem.cnp" class="o-default-body">
          {{ $t('common.addressLabels.identificationNumber') }}: {{ userDetailsItem.cnp }}
        </div>
        <div v-if="userDetailsItem.registrationNumber" class="o-default-body">
          {{ $t('common.addressLabels.referenceNumber') }}: {{ userDetailsItem.registrationNumber }}
        </div>
        <div v-if="userDetailsItem.address1">
          <span class="o-default-body">{{ $t('common.addressLabels.address') }}: {{ userDetailsItem.address1 }}</span>
          <span v-if="userDetailsItem.address2" class="o-default-body">, {{ userDetailsItem.address2 }}</span>
          <span v-if="userDetailsItem.city" class="o-default-body">, {{ userDetailsItem.city }}</span>
          <span v-if="userDetailsItem.state" class="o-default-body">, {{ userDetailsItem.state }}</span>
          <span v-if="userDetailsItem.country" class="o-default-body">, {{ userDetailsItem.country }}</span>
        </div>
        <div v-if="userDetailsItem.email" class="o-default-body">
          {{ $t('common.addressLabels.email') }}: {{ userDetailsItem.email }}
        </div>
        <div v-if="userDetailsItem.phone" class="o-default-body">
          {{ $t('common.addressLabels.phone') }}: {{ userDetailsItem.phone }}
        </div>
      </div>
      <div class="o-dialog-buttons">
        <md-button @click.stop="openConfirmActionDialog($t('common.confirmation.setAsDefault'), changeDefaultUserDetails, userDetailsItem.id)"
                   class="md-raised md-primary">
          {{ $t('userDetailsOther.setAsDefault') }}
        </md-button>
        <md-button @click.stop="editUserDetailsOther(userDetailsItem)"
                   class="md-raised md-primary">
          {{ $t('common.actions.editData') }}
        </md-button>
        <md-button @click.stop="openConfirmActionDialog($t('common.confirmation.delete'), deleteUserDetails, userDetailsItem.id)"
                   class="md-raised md-accent md-primary">
          {{ $t('common.actions.deleteData') }}
        </md-button>
      </div>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false">{{ $t('common.actions.close') }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div> <!-- /.UserDetailsOther -->
</template>

<script>
import { mapGetters } from 'vuex';
import Helpers from '@/common/helpers.js';
import Loader from '@/components/util/Loader';
import LocalStorageService from '@/common/localstorage.service';
import UserDetailsForm from '@/components/user-details/UserDetailsForm.vue';
import UserDetailsItem from '@/components/user-details/UserDetailsItem';
import {
  POST_USER_DETAILS_SET_DEFAULT,
  POST_USER_DETAILS_REMOVE,
  SET_USER_DETAILS_OTHER_EDIT_STATE_FLAG,
  UPDATE_USER_DETAILS
} from '@/store/actions.type';

export default {
  name: 'UserDetailsOther',
  props: {
    userDetailsNonDefault: {
      type: Array,
      required: false
    }
  },
  components: {
    UserDetailsForm,
    UserDetailsItem,
    Loader
  },
  computed: {
    ...mapGetters(['isUserDetailsOtherEditState'])
  },
  data() {
    return {
      userDetailsItem: null,
      confirmAction: {
        showDialog: false,
        message: ''
      },
      showDialog: false,
      handleFunctionCall: null,
      isLoading: false
    }
  },
  methods: {
    openConfirmActionDialog(msg, foo, fooParam) {
      this.confirmAction.message = msg;
      this.handleFunctionCall = foo;
      this.confirmActionFooParam = fooParam
      this.showDialog = false;
      this.confirmAction.showDialog = true;
    },
    showUserDetailsItemDialog(userDetailsItem) {
      this.userDetailsItem = { ...userDetailsItem }
      this.showDialog = true;
    },
    changeDefaultUserDetails(id) {
      this.$store.dispatch(POST_USER_DETAILS_SET_DEFAULT, id)
        .then(() => {
          LocalStorageService.setSessionStorage('isUpdateSuccess', 'true');
          location.reload();
        });
    },
    editUserDetailsOther(details) {
      this.showDialog = false;
      this.isLoading = true;
      this.$store.dispatch(UPDATE_USER_DETAILS, details)
        .then(() => {
          this.$store.dispatch(SET_USER_DETAILS_OTHER_EDIT_STATE_FLAG, true)
            .then(() => {
              Helpers.scrollIntoView('UserDetailsOther');
            })
            .finally(() => {
              this.isLoading = false;
            });
        });
    },
    deleteUserDetails(id) {
      this.$store.dispatch(POST_USER_DETAILS_REMOVE, id)
        .then(() => {
          LocalStorageService.setSessionStorage('isUpdateSuccess', 'true');
          location.reload();
        });
    }
  }
}
</script>

<style lang="scss">
.UserDetailsOther {

  .md-list-item-content {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>

<style lang="scss" scoped>
.UserDetailsOther {

  .md-list {
    padding: 0 !important;
  }

  .card-user-details-other {
    margin: 1.6rem 0;
  }

  .with-divider {
    border-bottom: $palette-secondary-color-three .1rem solid;

    &:last-of-type {
      border-bottom: none;
    }
  }
}
</style>
