<template>
  <div class="UserDetailsAdd">
    <div v-if="showAddBtn()" class="add-new-details-btn-container">
      <md-button @click="addNewDetails()" class="md-primary md-raised add-new-details-btn">
        <i class="material-icons">add</i>
        <span>{{ $t('common.actions.addNewEntry') }}</span>
      </md-button>
    </div>

    <md-card v-else-if="isUserDetailsAddState" class="card-user-details-add">
      <md-card-header>
        <md-card-header-text>
          <div class="md-title">{{ $t('userDetailsAdd.title') }}</div>
          <span class="o-default-body">{{ $t('userDetailsAdd.description') }}</span>
        </md-card-header-text>
      </md-card-header>
      <Loader v-if="isLoading" />
      <md-card-content v-else>
        <UserDetailsForm />
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Helpers from '@/common/helpers.js';
import Loader from '@/components/util/Loader';
import UserDetailsForm from '@/components/user-details/UserDetailsForm.vue';
import {
  RESET_USER_DETAILS,
  SET_USER_DETAILS_ADD_STATE_FLAG
} from '@/store/actions.type';

export default {
  name: 'UserDetailsAdd',
  components: {
    Loader,
    UserDetailsForm
  },
  data() {
    return {
      editState: false,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters([
      'isUserDetailsAddState',
      'isUserDetailsEditState',
      'isUserDetailsOtherEditState',
    ])
  },
  created() {
    this.$store.dispatch(RESET_USER_DETAILS);
  },
  methods: {
    showAddBtn() {
      return (this.isUserDetailsAddState || this.isUserDetailsEditState || this.isUserDetailsOtherEditState) ? false : true;
    },
    addNewDetails() {
      this.$store.dispatch(RESET_USER_DETAILS)
        .then(() => {
          this.$store.dispatch(SET_USER_DETAILS_ADD_STATE_FLAG, true)
            .then(() => {
              Helpers.scrollIntoView('UserDetailsAdd');
            });
        });
    }
  }
}
</script>

<style lang="scss">
.UserDetailsAdd {

  .add-new-details-btn {

    .md-button-content {
      display: flex;
    }
  }
}
</style>

<style lang="scss" scoped>
.UserDetailsAdd {

  .add-new-details-btn-container {
    text-align: left;
  }

  .add-new-details-btn {
    @include flex-center-xy;
    height: 4rem;
    margin: 2.4rem 0;
    min-width: 24rem;
    width: 100%;
    @include desktop { width: auto; }

    i, span {
      line-height: 4rem;
    }
  }

  .card-user-details-add {
    margin: 1.6rem 0;
  }
}
</style>
